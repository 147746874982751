@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap"); /* El yazısı fontunu buradan getiriyoruz */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html,
#root,
.container {
  height: 100%;
  margin: 0;
}

/* Yükleme ekranı */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}
.refinery {
  border-radius: 17px;
}
.loading-text {
  font-size: 24px;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  color: black;
}

.dot {
  animation: dot-blink 1s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* PDF Görünümü İçin */
.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.pdf-viewer {
  width: 80%;
  height: 90%;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

/* Video ve diğer içerik */
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.container {
  background-image: url("./background.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-title {
  font-family: "Pacifico", cursive;
  font-size: 36px;
  color: white !important;
  margin-bottom: 40px;
  text-align: center;
  line-height: 27px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3), 0 0 10px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.3);
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 15px;
}

.apps {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.badge {
  position: absolute;
  right: 50%;
  bottom: -10px;
  transform: translateX(50%);
  padding: 4px 12px;
  font-size: 10px;
  font-weight: bold;
  color: white;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  z-index: 1;
}

/* Soon badge - Altın ve gradient */
.soon-badge {
  background: linear-gradient(90deg, #ff9900, #ffcc00);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

/* Live badge - Renkli ve gradient */
.live-badge {
  background: linear-gradient(90deg, #86ef05, #00bfff);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

/* App kartlarını düzenleyin */
.app {
  position: relative;
  padding: 12px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  cursor: pointer;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.app:hover {
  transform: translateY(-5px);
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.app a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.app p {
  font-weight: 600;
  color: white;
  font-size: 14px !important;
  margin-top: 12px;
  margin-bottom: 8px;
  letter-spacing: 0.5px;
}

.app-icon {
  width: 90px;
  height: 90px;
  object-fit: cover;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 16px !important;
}

.bottom-logo {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 100px;
  height: auto;
}

footer {
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  color: white;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
}

footer p {
  margin: 0;
  backdrop-filter: blur(10px);
  border-radius: 5px;
  padding: 5px;
}

.contact-link {
  color: white;
  text-decoration: underline;
}

.contact-link:hover {
  color: #ffcc00;
}

/* Mobil Cihazlar İçin Düzenlemeler */
@media (max-width: 768px) {
  .container {
    background-image: url("./background_mobile.png") !important;
    padding: 20px;
    justify-content: flex-start;
    padding-top: 60px;
    overflow: hidden;
    height: 100%;
  }

  .app-title {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .apps {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding: 10px;
    max-width: 100%;
  }

  .app {
    padding: 8px;
    border-radius: 16px;
  }

  .app-icon {
    width: 70px;
    height: 70px;
    border-radius: 12px;
  }

  .app p {
    font-size: 12px !important;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  .badge {
    font-size: 8px;
    padding: 2px 8px;
    bottom: -8px;
  }

  .bottom-logo {
    width: 40px;
    top: 15px;
    bottom: auto;
    right: 15px;
  }

  footer {
    padding: 10px;
    font-size: 10px;
    justify-content: center;
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
  }

  footer p {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 380px) {
  .apps {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .app-icon {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }

  .app p {
    font-size: 11px !important;
  }

  .badge {
    font-size: 7px;
    padding: 2px 6px;
  }
}

.privacy-policy-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-policy-container h1 {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.privacy-content section {
  margin-bottom: 30px;
}

.privacy-content h2 {
  color: #444;
  margin-bottom: 15px;
  font-size: 1.5em;
}

.privacy-content p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 15px;
}

.privacy-content ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #666;
}

.privacy-content li {
  margin-bottom: 8px;
  line-height: 1.4;
}

.apps a.app {
  text-decoration: none;
  color: inherit;
}

/* Improved redirect page styling */
.redirect-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  font-family: "Arial", sans-serif;
  text-align: center;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.redirect-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: -1;
}

.redirect-content {
  background: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  width: 400px;
  position: relative;
  z-index: 1;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.redirect-logo {
  width: 120px;
  height: 120px;
  margin-bottom: 30px;
  border-radius: 24px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: pulse 2s infinite ease-in-out;
}

.redirect-text {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  max-width: 100%;
}

.redirect-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.spinner-dot {
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 50%;
  background-color: #0066cc;
  animation: bounce 1.4s infinite ease-in-out both;
}

.spinner-dot:nth-child(1) {
  animation-delay: -0.32s;
}

.spinner-dot:nth-child(2) {
  animation-delay: -0.16s;
}

.redirect-app-store {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-store-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0066cc;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 102, 204, 0.3);
}

.app-store-button:hover {
  background-color: #0055aa;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 102, 204, 0.4);
}

.app-store-button svg {
  margin-right: 8px;
}

.app-store-button svg path {
  fill: white;
}

.app-store-badge {
  height: 50px;
  margin-top: 15px;
  transition: transform 0.3s ease;
}

.app-store-badge:hover {
  transform: scale(1.05);
}

.redirect-error {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 0 auto;
}

.redirect-error a {
  color: #0066cc;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.redirect-error a:hover {
  color: #004499;
  text-decoration: underline;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/* Mobile adjustments for redirect page */
@media (max-width: 768px) {
  .redirect-content {
    padding: 30px;
    width: 90%;
  }

  .redirect-logo {
    width: 100px;
    height: 100px;
  }

  .redirect-text {
    font-size: 20px;
  }

  .app-store-badge {
    height: 40px;
  }

  .app-store-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .app-store-button svg {
    width: 20px;
    height: 20px;
  }
}
